<template>
  <v-card flat>
    <v-container class="pa-12">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          outlined
          placeholder=""
          type="email"
          required
          rounded
          @keyup.enter="validate"
        />

        <v-row v-if="error || !valid" class="pb-6">
          <v-col cols="12">
            <div class="red--text text-center aling-center">
              {{ error }}
            </div>
          </v-col>
        </v-row>

        <v-btn
          :loading="loading"
          color="orange darken-3"
          :dark="valid || !loading"
          class="mr-4"
          block
          rounded
          large
          @click.prevent="validate"
        >
          Enviar e-mail para redefinir senha
        </v-btn>

        <v-row class="pa-6">
          <v-col cols="12">
            <div class="blue--text text-center aling-center">
              <router-link to="/login">Ir para Login</router-link>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-snackbar
      v-model="snackbar"
      :multi-line="snackbarMultiLine"
      :color="snackbarColor"
      timeout=6000
      :top="true"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script>
  import { mapActions } from 'vuex'
  import { rememberPassword } from '../../services/users'
  export default {
    name: 'LoginForm',
    data: () => ({
      valid: true,
      email: '',
      emailRules: [v => !!v || "Por favor, informe o seu email", v => /.+@.+/.test(v) || "E-mail precisa ser válido"],
      error: null,
      loading: false,
      snackbar: false,
      snackbarColor: 'green',
      snackbarText: '',
      snackbarMultiLine: true,
    }),

    methods: {
      ...mapActions(['login']),
      validate () {
        if (!this.$refs.form.validate()) {
          this.error = 'Informe os dados solicitados'
          return
        }
        this.loading = true
        rememberPassword(this.email)
          .then(result => {
            console.log('result? ', result)
            if (result?.code) {
              this.loading = false
              if (result.code === "auth/wrong-password") {
                this.error = "Ops! Senha inválida."
                return
              } else if (result.code === "auth/user-not-found") {
                this.error = "Ops! Email não encontrado em nossa base, tem certeza que se cadastrou com esse e-mail?"
                return
              } else if (result.code === "auth/too-many-request") {
                this.error = "Ops! O acesso a esta conta foi temporariamente desativado devido a muitas tentativas de login malsucedidas. Você pode restaurá-lo imediatamente redefinindo sua senha ou pode tentar novamente mais tarde."
                return
              } else {
                this.error = "Ops! " + result.code + ": " + result.message
                return
              }
            } else {
              if (result) {
                    this.showSnackBar('E-mail enviado com sucesso!', 'green')
                    this.$router.replace("/login")
              }
            }
        })
        .catch(error => {
          console.error("Error: ", error)
          this.loading = false
          if (error.code === "auth/wrong-password") {
            this.error = "Ops! Senha inválida."
          } else if (error.code === "auth/user-not-found") {
            this.error = "Ops! Email não encontrado em nossa base, tem certeza que se cadastrou com esse e-mail?"
          } else {
            this.error = "Ops! " + error.code + ": " + error.message
          }
        })
    },
    showSnackBar(text, color) {
        this.snackbarText = text,
        this.snackbarColor = color
        this.snackbar = true
    },
  }
}
</script>
